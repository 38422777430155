import { SITE_URL } from "../component/common/Backend";


export const User = ({ email, password }) => {
  return fetch(`${SITE_URL}/api/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email, password })
  })
    .then(data => {
        return data.json();
    })
    .catch(err => {
      console.error('Login failed:', err);
    });
};
