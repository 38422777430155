// CustRouter.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import UserLogin from './component/Login';
import ListingComponent from './component/admin/ListingComponent';
import AdminRoute from './component/admin/AdminRoute';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/signout" element={<UserLogin />} />

        <Route
          path="/admin/addlisting"
          element={<AdminRoute element={<ListingComponent />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
