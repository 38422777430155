import React, { useState } from 'react';
import Base from '../../Base';
import { Cars } from '../../api/Cars';
import { ToastContainer } from 'react-toastify';
import { showErrorMessage, showSuccessMessage } from '../../helpers/commonhelper';

const ListingComponent = () => {
    const successfulLogin = localStorage.getItem('logintoast');
    if (successfulLogin === 'true') {
        showSuccessMessage('Logged In!');
    }
    const [values, setValues] = useState({ c_name: "", price: "", model: "", year: "", year_built: "", kms: "", file_url: "", car_meta: { air_bag: "", hp: "", c_color: "", sun_roof: false, alloy_wheels: false, seating_capacity: "", fuel_type: "" }, seller_info: { address: "", phone: "", lat: "", lon: "", }, c_status: "", owners: "" }); const { c_name, price, model, year, year_built, kms, c_color, address, phone, lat, lon, fuel_type } = values;

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handelOnfileChange = name => e => { setUploadedFiles([...e.target.files]); }

    const handelOnChange = name => e => {
        if (name.includes('.')) {
            const [parentField, childField] = name.split('.');
            setValues({
                ...values,
                [parentField]: {
                    ...values[parentField],
                    [childField]: e.target.value
                }
            });
        } else {
            setValues({ ...values, [name]: e.target.value })
        }
    }

    const handelSubmit = () => {
        Cars({ values, uploadedFiles })
            .then(data => {
                if (data.error) {
                    showErrorMessage(data.error)
                } else {
                    showSuccessMessage("Wow Car Added In Your List!")
                }
            })
            .catch((error) => {
            });
    }


    return (
        <Base className="ltn__appointment-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-8">
                            </div>
                        </div>
                        <div className="ltn__appointment-inner">
                            <h2>1. Description</h2>
                            <p><small>These fields are mandatory: Title, Property Media</small></p>
                            <h6>Car Description</h6>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" name="c_name" value={c_name} onChange={handelOnChange('c_name')} id="c_name" placeholder="*Name (mandatory)" />
                                    </div>
                                </div>
                            </div>
                            <h6>Car Price</h6>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" name="price" value={price} onChange={handelOnChange('price')} id="price" placeholder="Price in $ (only numbers)" />
                                    </div>
                                </div>
                            </div>
                            <h6>Select Status</h6>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="input-item">
                                        <select className="nice-select" name='c_status' id='c_status' onChange={handelOnChange('c_status')} required>
                                            <option value=''>Select Status</option>
                                            <option value='true'>Active</option>
                                            <option value='false'>Deactivate</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <h2>2. Media</h2>
                            <h6>Listing Media</h6>
                            <input type="file" onChange={handelOnfileChange('photos')} id="photos" name="photos" className="btn theme-btn-3 mb-10" multiple /><br />
                            <p>
                                <small>* At least 1 image is required for a valid submission.Minimum size is 500/500px.</small><br />
                                <small>* PDF files upload supported as well.</small><br />
                                <small>* Images might take longer to be processed.</small>
                            </p>

                            <h2>3. Location</h2>
                            <h6>Listing Location</h6>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={address} name="address" onChange={handelOnChange('seller_info.address')} id="address" placeholder="*Address" />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={phone} name="phone" onChange={handelOnChange('seller_info.phone')} id="phone" placeholder="*Phone" />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="property-details-google-map mb-60">
                                        <iframe title='dasd' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={lat} name="lat" id="lat" onChange={handelOnChange('seller_info.lat')} placeholder="Latitude (for Google Maps)" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={lon} name="lon" id="lon" onChange={handelOnChange('seller_info.lon')} placeholder="Longitude (for Google Maps)" />
                                    </div>
                                </div>

                            </div>
                            <h2>4. Details</h2>
                            <h6>Listing Details</h6>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={model} name="model" onChange={handelOnChange('model')} id="model" placeholder="Model" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={year} name="year" onChange={handelOnChange('year')} id="year" placeholder="Year" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={kms} name="kms" onChange={handelOnChange('kms')} id="kms" placeholder="KMS" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={c_color} name="c_color" onChange={handelOnChange('car_meta.c_color')} id="c_color" placeholder="Color" />
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <select className="nice-select" onChange={handelOnChange('car_meta.hp')} id="hp" name="hp">
                                            <option value=""> Hypothecation </option>
                                            <option value="true"> Yes </option>
                                            <option value="false"> No </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <select className="nice-select" onChange={handelOnChange('car_meta.air_bag')} id="air_bag" name="air_bag">
                                            <option value=""> Air Bags </option>
                                            <option value="0"> 0 </option>
                                            <option value="1"> 1 </option>
                                            <option value="3"> 3 </option>
                                            <option value="4"> 4 </option>
                                            <option value="5"> 5 </option>
                                            <option value="6"> 6 </option>
                                            <option value="7"> 7 </option>
                                            <option value="8"> 8 </option>
                                            <option value="9"> 9 </option>
                                        </select>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <select className="nice-select" onChange={handelOnChange('car_meta.seating_capacity')} id="seating_capacity" name="seating_capacity">
                                            <option value=""> Seating Capacity </option>
                                            <option value="1"> 1 </option>
                                            <option value="2"> 2 </option>
                                            <option value="3"> 3 </option>
                                            <option value="4"> 4 </option>
                                            <option value="5"> 5 </option>
                                            <option value="6"> 6 </option>
                                            <option value="7"> 7 </option>
                                            <option value="8"> 8 </option>
                                            <option value="9"> 9 </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={year_built} name="year_built" onChange={handelOnChange('year_built')} id="year_built" placeholder="Year Built (*numeric)" />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="input-item input-item-name ltn__custom-icon">
                                        <input type="text" value={fuel_type} name="fuel_type" onChange={handelOnChange('car_meta.fuel_type')} id="fuel_type" placeholder="Fuel Type" />
                                    </div>
                                </div>


                            </div>

                            <h2>5. Optional</h2>
                            <h6>Car Features</h6>
                            <h6>Interior Details</h6>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <label className="checkbox-item">Sun Roof
                                        <input type="checkbox" value="true" onChange={handelOnChange('car_meta.sun_roof')} id="sun_roof" name="sun_roof" />
                                        <span className="checkmark" />
                                    </label>
                                </div>


                                <div className="col-lg-4 col-md-6">
                                    <label className="checkbox-item">Alloy Wheels
                                        <input type="checkbox" value="true" onChange={handelOnChange('car_meta.alloy_wheels')} id="alloy_wheels" name="alloy_wheels" />
                                        <span className="checkmark" />
                                    </label>
                                </div>


                            </div>

                            <div className="alert alert-warning d-none" role="alert">
                                Please note that the date and time you requested may not be available. We will contact you to confirm your actual appointment details.
                            </div>
                            <div className="btn-wrapper text-center mt-30">
                                <input type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={() => handelSubmit()} value="submit" />
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </Base>
    )

}

export default ListingComponent