import { isAuthenticated } from "../auth/auth";
import { SITE_URL } from "../component/common/Backend";

export const Cars = ({ values, uploadedFiles }) => {
    const formData = new FormData();

    uploadedFiles.forEach((file, index) => {
        formData.append(`photos`, file);
      });

    for (const key in values) {
        formData.append(key, values[key]);
    }

    const user = isAuthenticated();
    console.log(user)

    return fetch(`${SITE_URL}/api/cars/create`, {
        method: "POST",
        headers: {
            "Authorization": user.token
        },
        body: formData
    })
        .then(data => {
            return data.json();
        })
        .catch(err => {
            console.error('Error', err);
        });
};
