import React from 'react';

const Aboutus = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'

    return (
        <div className="ltn__about-us-area pt-120 pb-90 ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-img-wrap about-img-left">
                            <img src={publicUrl + "assets/img/others/7.png"} alt="" />
                            <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                                <div className="ltn__video-img ltn__animation-pulse1">
                                    <img src={publicUrl + "assets/img/others/8.png"} alt="" />
                                    <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
                                        <i className="fa fa-play" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
                                <h1 className="section-title">The Leading Car
                                Buy/Sell Marketplace<span>.</span></h1>
                            </div>
                            <ul className="ltn__list-item-half clearfix">
                                <li>
                                    <i className="flaticon-car" />
                                    Certified Cars
                                </li>
                                <li>
                                    <i className="flaticon-mountain" />
                                    Best Price
                                </li>
                                <li>
                                    <i className="flaticon-heart" />
                                    Best Condition
                                </li>
                                <li>
                                    <i className="flaticon-secure" />
                                    Free Car Insurance
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Aboutus;