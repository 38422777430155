import React from "react";
import Navbar from "./component/common/Navbar";

const Base = ({ children, className }) => {
    return (
            <div>
                <Navbar />
                <div className={className}>{children}</div>
            </div>

    )
}
export default Base;