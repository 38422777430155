
export const AuthenticateUser = (data) =>{
    localStorage.setItem('user', JSON.stringify(data));
    window.location.href = 'admin/addlisting';
}

export const isAuthenticated = () =>{
    if(localStorage.getItem('user')){
        return JSON.parse(localStorage.getItem('user'))
    }
    return false;
}

export const signOut = ()=>{
    localStorage.removeItem('user');
    window.location.href = '/login';
}
