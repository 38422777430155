// AdminRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../auth/auth';

const AdminRoute = ({ element }) => {
  return isAuthenticated() && isAuthenticated().role === 1 ? (
    <React.Fragment>{element}</React.Fragment>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default AdminRoute;
