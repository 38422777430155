import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../component/common/Navbar';
import { User } from '../api/User';
import { AuthenticateUser } from '../auth/auth';
import { ToastContainer } from 'react-toastify';
import { showErrorMessage} from '../helpers/commonhelper';

const UserLogin = () => {
    const [values, setValues] = useState({ email: "arbaz@gmail.com", password: "" });
    const { email, password } = values;

    const handelOnChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    }

    const submitUser = () => {
        User({ email, password })
            .then(data => {
                if (data.error) {
                    showErrorMessage(data.error);
                } else {
                    AuthenticateUser(data.user);
                    localStorage.setItem('logintoast', 'true');
                }
            })
            .catch((error) => {
                showErrorMessage("Error");
            });
    }

    return (
        <div>
            <Navbar />
            <ToastContainer />
            <div>
                <div className="ltn__login-area pb-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1 className="section-title">Sign In <br />To Your Account</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="account-login-inner">
                                    <div className="ltn__form-box contact-form-box">
                                        <input type="text" name="email" placeholder="Email*" onChange={handelOnChange("email")} value={email} />
                                        <input type="password" name="password" placeholder="Password*" onChange={handelOnChange("password")} value={password} />
                                        <div className="btn-wrapper mt-0">
                                            <button className="theme-btn-1 btn btn-block" type="submit" onClick={() => submitUser()}>SIGN IN</button>
                                        </div>
                                        <div className="go-to-btn mt-20">
                                            <button title="Forgot Password?" data-bs-toggle="modal" data-bs-target="#ltn_forget_password_modal"><small>FORGOTTEN YOUR PASSWORD?</small></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="account-create text-center pt-50">
                                    <h4>DON'T HAVE AN ACCOUNT?</h4>
                                    <p>Add items to your wishlistget personalised recommendations <br />
                                        check out more quickly track your orders register</p>
                                    <div className="btn-wrapper go-top">
                                        <Link to="/register" className="theme-btn-1 btn black-btn">CREATE ACCOUNT</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ltn__modal-area ltn__add-to-cart-modal-area----">
                    <div className="modal fade" id="ltn_forget_password_modal" tabIndex={-1}>
                        <div className="modal-dialog modal-md" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">x</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="ltn__quick-view-modal-inner">
                                        <div className="modal-product-item">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="modal-product-info text-center">
                                                        <h4>FORGET PASSWORD?</h4>
                                                        <p className="added-cart"> Enter you register email.</p>
                                                        <form action="#" className="ltn__form-box">
                                                            <input type="text" name="email" placeholder="Type your register email*" />
                                                            <div className="btn-wrapper mt-0">
                                                                <button className="theme-btn-1 btn btn-full-width-2" type="submit">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    {/* additional-info */}
                                                    <div className="additional-info d-none">
                                                        <p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
                                                        <div className="payment-method">
                                                            <img src={process.env.publicUrl + "assets/img/icons/payment.png"} alt="#" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default UserLogin


