// commonhelper.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showErrorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
  });
};

export const showSuccessMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    onClose: () => {
      localStorage.removeItem('logintoast');
    },
  });
};
