import React from "react";
import { Link } from 'react-router-dom';
import Base from "./Base";
import SearchBox from "./component/common/SearchBox";
import Aboutus from "./Aboutus";
import { Counter } from "./component/common/Counter";
import ProductListing from "./component/common/ProductListing";

const Home = () => {
	
	return (
		<>
		<Base className="ltn__slider-area ltn__slider-3  section-bg-1 go-top">
			<div className="ltn__slider-area ltn__slider-3  section-bg-1 go-top">
				<div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
					<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 pb-0">
						<div className="ltn__slide-item-inner">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 align-self-center">
										<div className="slide-item-info">
											<div className="slide-item-info-inner ">
												<div className="slide-video mb-50 d-none">
													<a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
														<i className="fa fa-play" />
													</a>
												</div>
												<h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-car" /></span> Poonacars by shadab khan</h6>
												<h1 className="slide-title animated ">Find Your Dream <br /> Car By Us</h1>
												<div className="slide-brief animated">
													<p>We provide used cars at best price with certifcate of authentication</p>
												</div>
												<div className="btn-wrapper animated ">
													<Link to="/about" className="theme-btn-1 btn btn-effect-1 go-top">Make An Enquiry</Link>
													<a className="ltn__video-play-btn bg-white" href="https://www.youtube.com/embed/HnbMYzdjuBs?autoplay=1&showinfo=0" data-rel="lightcase">
														<i className="icon-play  ltn__secondary-color" />
													</a>
												</div>
											</div>
										</div>
										<div className="slide-item-img">
											<img src={"/assets/img/slider/slider_1.jpg"} alt="#" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<SearchBox />
				</div>
			</div>
			<ProductListing/>
			<Aboutus />
			<Counter/>

		</Base>
			</>

	)
}

export default Home;